import React from 'react';

const useDynamicScript = (args: { url: string }) => {
    const [ready, setReady] = React.useState(false);
    const [failed, setFailed] = React.useState(false);

    React.useEffect(() => {
        if (!args.url) {
            console.error(`Module URL empty`);
            setReady(true);
            setFailed(true);
            return;
        }

        const element = document.createElement('script');

        element.src = args.url;
        element.type = 'text/javascript';
        element.async = true;

        setReady(false);
        setFailed(false);

        element.onload = () => {
            console.log(`Module Loaded: ${args.url}`);
            setReady(true);
        };

        element.onerror = () => {
            console.error(`Module Error: ${args.url}`);
            setReady(true);
            setFailed(true);
        };

        document.head.appendChild(element);

        return () => {
            console.log(`Module Removed: ${args.url}`);
            document.head.removeChild(element);
        };
    }, [args.url]);

    return {
        ready,
        failed,
    };
};

export default useDynamicScript;
