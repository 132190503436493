import React, { Suspense, useEffect } from 'react';

import { Grid } from '@mui/material';

import ChargementEnCours from './composants/molecules/chargementEnCours/chargementEnCours';
import useDynamicScript from './hooks/useDynamicScript';

let components: Record<string, React.LazyExoticComponent<any>> = {};
let remotesDefinitions: Record<string, string>;

function getRemoteDef(module: string): string {
    return remotesDefinitions[module];
}

export function setRemoteDef(remotes: any) {
    if (!remotes || !remotes['imports']) {
        throw Error('error imports definitions');
    }
    remotesDefinitions = remotes['imports'];
}

function loadComponent(scope: string, module: string) {
    return async () => {
        try {
            // Initializes the share scope. This fills it with known provided modules from this build and all remotes
            await __webpack_init_sharing__('default');
            //@ts-ignore
            const container = window[scope]; // or get the container somewhere else
            // Initialize the container, it may provide shared modules
            //@ts-ignore
            await container.init(__webpack_share_scopes__.default);
            //@ts-ignore
            const factory = await window[scope].get(module);
            const Module = factory();
            return Module;
        } catch (error) {
            console.error(error);
            // ici faire import d'un composant indiquant une erreur à été rencontrée
            return import('./composants/molecules/chargementEnCours/chargementEnCours');
        }
    };
}

function ModuleLoader(props: any) {
    const { ready, failed } = useDynamicScript({
        url: getRemoteDef(props.scope),
    });

    if (!props.module) {
        return <h2>Not Remote Module specified</h2>;
    }

    if (!ready) {
        return (
            <Grid sx={{ width: '100%', textAlign: 'center' }}>
                <ChargementEnCours />
            </Grid>
        );
    }

    if (failed) {
        // ici utilisé le composant indiquant une erreur à été rencontrée + indiquer le détails
        return (
            <Grid sx={{ width: '100%', textAlign: 'center' }}>
                <h2>Failed to load module: {props.scope}</h2>
            </Grid>
        );
    }

    let Component = components[props.scope];
    if (!Component) {
        Component = React.lazy(loadComponent(props.scope, props.module));
        components[props.scope] = Component;
    }

    document.addEventListener('LOGOUT', () => {
        // Clear components
        components = {};
    });

    return (
        <Suspense
            fallback={
                <Grid sx={{ width: '100%', textAlign: 'center' }}>
                    <ChargementEnCours />
                </Grid>
            }
        >
            <Component />
        </Suspense>
    );
}

export default ModuleLoader;
